import logo from './logo.svg';
import './App.css';

import React from 'react'
import react, { useState, useRef, useEffect} from 'react';
import { Container, Accordion, Card, Button, Navbar, Nav, NavDropdown, Form, FormControl, Dropdown } from "react-bootstrap";
import Question from './Question';
import QuestionAvecExclusion from './QuestionAvecExclusion';

import Boutonexcluinput from './Boutonexcluinput';
import Boutonexclu from './Boutonexclu';

import NvxBandeauINES from './image/NextStage.jpeg';

import firestore from "./firestore";
import firebase from "firebase";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


import { useHistory } from 'react-router-dom'

// IMPORT PHOTO LETTRE
import LA from './image/LA.PNG';
import LB from './image/LB.PNG';
import LC from './image/LC.PNG';
import LD from './image/LD.PNG';
import LE from './image/LE.PNG';


const Detailsint = ({ alert }) => {

    let history = useHistory();    
    // MODE (payant/gratuit ; mdscs/ines)
const [ModeGratuit, setModeGratuit] = useState(false);
const [ModeInes, setModeInes] = useState(true);


return (

<div className="App">

        <div className='FR'>
        <button className={"btnFR"} onClick={() =>history.push('/')}>Return French version score</button>
        <br/>
        <button className={"btnFR"} onClick={() =>history.push('/int')}>Return other countrie's version score</button>
        <br/>

        </div>

        <div className='detail'>
            <button className={"btnFR"} onClick={() =>history.push('/infoint')}>MDS score information</button>
        </div>            

    <div className="Header">


        <div className='logo'>
        <img src={NvxBandeauINES} width="283" height="87" />
        </div>

    </div>  

 
    <div className="droitegauche">

        <div className="gauche">


            <div className="gaucherectangle">



                <div className="accordion"> 

                    <div className='FRmobile'>
                        <button className={"btnFRmobile"} onClick={() =>history.push('/')}>Return French version score</button>
                        <br/>
                        <button className={"btnFRmobile"} onClick={() =>history.push('/int')}>Return other countrie's version score</button>
                        <br/><br/>
                        <button className={"btnFRmobile"} onClick={() =>history.push('/infoint')}>MDS score information</button>
                    </div>
                </div>
                    <div className='textedetail'>
                <p>What is the MDS score?</p>

<p>The evaluation of a clinical digital solution is long and difficult because it requires solid knowledge in areas as varied as clinical study methodology, embedded technology, clinical relevance, etc. These difficulties still too often lead to neglecting high-quality solutions and favoring immature solutions.</p>

<p>We have designed, with 130 publishers of e-health solutions, health establishments, doctors, health authorities recommendations and patient associations, the MDS score (Medical Digital Solution Score) which is a prescreening score for the simplified evaluation of the clinical quality and relevance of eHealth Apps. It is a score allowing to evaluate in 26 questions :</p>

<p>&bull; the clinical field and claim of the clinical application (excluding well-being) (score /500)</p>

<p>
&bull; the target of the solution (score /500)</p>

<p>
&bull; the clinical evaluation of the solution (score /500)</p>

<p>
&bull; the editor (score /500)</p>

<p>
&bull; the probability of reimbursement</p>

<p>Either an overall score /2000</p>

<p>It was evaluated as part of an experiment and published in 2022 in the international medical journal JMIR (Journal of Medical Internet Research) whose results are here&#8230; <a href="https://pubmed.ncbi.nlm.nih.gov/35788102/">https://pubmed.ncbi.nlm.nih.gov/35788102/</a></p>

<p>Letters from (A to E) are displayed with colors to facilitate reading and the position of the solution evaluated in relation to all the solutions evaluated in the study:</p>

<p><img src={LA} width="24" height="17" /> for scores of 15/20 or more (1st quintile, i.e. the 20% of the best-scored solutions)<br />
<img src={LB} width="24" height="17" /> for scores from 13 to 14.9/ (2nd quintile)<br />
<img src={LC} width="24" height="17" /> for scores from 10 to 12.9/20 (3rd quintile)<br />
<img src={LD} width="24" height="17" /> for scores from 8 to 9.9/ (4th quintile)<br />
<img src={LE} width="24" height="17" /> for scores below 8/20 (bottom quintile)</p>

<p>This score allows to:</p>

<p>&ndash; guide solution providers towards the best R&amp;D choices<br />
&ndash; to help in the choice and comparison of solutions with relevant and scalable reproducible criteria<br />
&ndash; help prescribers and institutional structures to evaluate solutions before deploying them<br />
&ndash; to have common evaluation criteria<br />
&ndash; The French National e-Health Institute&rsquo;s experts to select solutions in order to obtain distinctions for their digital health solutions.</p>



<p>Two versions of the score are available: <br />
&bull; The French version allows a targeted assessment on French territory by considering national characteristics and legislation.<br />
&bull; The international version, which allows evaluation on other countries with less specific evaluation criteria than for the French score.</p>

                </div>
            </div>
        </div> 
    </div>          
</div>
)
}


export default Detailsint
