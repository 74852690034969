import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Page1 from './Page1';
import Pageint from './Pageint';
import Detailsfr from './Detailsfr';
import Detailsint from './Detailsint';

import Bio from './Bio';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter, Route, Switch, Redirect, BrowserRouter as Router  } from 'react-router-dom'

const Root = () => {

 

return(
  <div className="App">
    <BrowserRouter>
  <Switch>
    <Route exact path='/' component={Page1}></Route>
    <Route exact path='/int' component={Pageint}></Route>
    <Route exact path='/infofr' component={Detailsfr}></Route>
    <Route exact path='/infoint' component={Detailsint}></Route>

    
  </Switch>
  </BrowserRouter>
  </div>



)


}

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
