import logo from './logo.svg';
import './App.css';

import React from 'react'
import react, { useState, useRef, useEffect} from 'react';
import { Container, Accordion, Card, Button, Navbar, Nav, NavDropdown, Form, FormControl, Dropdown } from "react-bootstrap";
import Question from './Question';
import QuestionAvecExclusion from './QuestionAvecExclusion';

import Boutonexcluinput from './Boutonexcluinput';
import Boutonexclu from './Boutonexclu';

import NvxBandeauINES from './image/NextStage.jpeg';

import firestore from "./firestore";
import firebase from "firebase";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

// IMPORT PHOTO LETTRE
import LA from './image/LA.PNG';
import LB from './image/LB.PNG';
import LC from './image/LC.PNG';
import LD from './image/LD.PNG';
import LE from './image/LE.PNG';


import { useHistory } from 'react-router-dom'


const Detailsfr = ({ alert }) => {

    let history = useHistory();    
    // MODE (payant/gratuit ; mdscs/ines)
const [ModeGratuit, setModeGratuit] = useState(false);
const [ModeInes, setModeInes] = useState(true);


return (

<div className="App">

        <div className='FR'>
        <button className={"btnFR"} onClick={() =>history.push('/')}>Return French version score</button>
        <br/>
        <button className={"btnFR"} onClick={() =>history.push('/int')}>Return other countrie's version score</button>
        <br/>

        </div>

        <div className='detailfr'>
            <button className={"btnFR"} onClick={() =>history.push('/infofr')}>Information sur le score MDS</button>
        </div>            

    <div className="Header">


        <div className='logo'>
        <img src={NvxBandeauINES} width="283" height="87" />
        </div>

    </div>  

 
    <div className="droitegauche">

        <div className="gauche">


            <div className="gaucherectangle">



                <div className="accordion"> 

                    <div className='FRmobile'>
                        <button className={"btnFRmobile"} onClick={() =>history.push('/')}>Return French version score</button>
                        <br/>
                        <button className={"btnFRmobile"} onClick={() =>history.push('/int')}>Returne other countrie's version</button>
                        <br/><br/>
                        <button className={"btnFRmobile"} onClick={() =>history.push('/infoint')}>Qu’est-ce que le score MDS ? </button>
                    </div>
                </div>
                    <div className='textedetail'>
                    <p>Qu&rsquo;est-ce que le score MDS ?</p>

<p>L&rsquo;&eacute;valuation d&rsquo;une solution num&eacute;rique clinique est longue et difficile car elle n&eacute;cessite de solides connaissances dans des domaines aussi vari&eacute;s que la m&eacute;thodologie d&rsquo;&eacute;tude clinique, la technologie embarqu&eacute;e, la pertinence clinique etc. Ces difficult&eacute;s am&egrave;nent encore trop souvent &agrave; n&eacute;gliger des solutions de haute qualit&eacute; et favoriser des solutions non matures.</p>

<p>Nous avons con&ccedil;u, avec 130 &eacute;diteurs de solutions en e-sant&eacute;, &eacute;tablissements de sant&eacute;, m&eacute;decins, autorit&eacute;s de sant&eacute; et associations de patients le score MDS (Medical Digital Solution Score) qui est un outil permettant une pr&eacute;-&eacute;valuation simplifi&eacute;e
de la qualit&eacute; clinique et de la pertinence des solutions num&eacute;riques en sant&eacute; &agrave; vis&eacute;e clinique (hors bien &ecirc;tre) avec un score permettant d&rsquo;&eacute;valuer en 26 questions :</p>

<p>&bull; le domaine clinique et la revendication de l&rsquo;application &agrave; vis&eacute;e clinique (note /500)</p>

<p>
&bull; la cible de la solution (note /500)</p>

<p>
&bull; l&rsquo;&eacute;valuation clinique de la solution (note /500)</p>

<p>
&bull; l&rsquo;&eacute;diteur (note /500)</p>

<p>
&bull; la probabilit&eacute; de remboursement</p>

<p>Soit une note globale /2000</p>

<p>Ce score a &eacute;t&eacute; &eacute;valu&eacute; dans le cadre d&rsquo;une exp&eacute;rimentation et publi&eacute; en 2022 dans la revue m&eacute;dicale internationale JMIR (Journal of Medical Internet Research) dont les r&eacute;sultats sont ici&#8230; <a href="https://pubmed.ncbi.nlm.nih.gov/35788102/">https://pubmed.ncbi.nlm.nih.gov/35788102/</a></p>

<p>Des lettres de (A &agrave; E) sont affich&eacute;es avec des couleurs pour faciliter la lecture et la position de la solution &eacute;valu&eacute;e par rapport &agrave; toutes les solutions &eacute;valu&eacute;es dans l&rsquo;&eacute;tude :</p>
<p><img src={LA} width="24" height="17" /> pour les notes de 15/20 ou plus (1er quintile, soit les 20% des solutions les mieux not&eacute;es)<br />
<img src={LB} width="24" height="17" /> pour les notes de 13 &agrave; 14,9/ (2&egrave; quintile)<br />
<img src={LC} width="24" height="17" /> pour les notes de 10 &agrave; 12,9/20 (3&egrave; quintile)<br />
<img src={LD} width="24" height="17" /> pour les notes de 8 &agrave; 9,9/ (4&egrave; quintile)<br />
<img src={LE} width="24" height="17" /> pour les notes inférieures à 8/20 (dernier quintile)<br />
</p>

<p>Ce score permet :</p>

<p>&ndash; d&rsquo;orienter les concepteurs de solution vers les meilleurs choix R&amp;D<br />
&ndash; d&rsquo;aider au choix et &agrave; la comparaison des solutions avec des crit&egrave;res reproductibles pertinents et &eacute;volutifs<br />
&ndash; d&rsquo;aider les prescripteurs et structures de sant&eacute; &agrave; &eacute;valuer les solutions avant de les d&eacute;ployer<br />
&ndash; d&rsquo;avoir des crit&egrave;res communs d&rsquo;&eacute;valuation<br />
&ndash; aux experts de l&rsquo;Institut National de la e-Sant&eacute; de s&eacute;lectionner des solutions afin d&rsquo;obtenir des distinctions de solutions digitales en sant&eacute;.</p>



<p>Deux versions sont disponibles : <br />
&bull; La version fran&ccedil;aise qui permet une &eacute;valuation cibl&eacute;e sur le territoire fran&ccedil;ais en prenant en compte les caract&eacute;ristiques et l&eacute;gislations nationales,<br />
&bull; La version internationale, qui est plus large et permet l&rsquo;&eacute;valuation sur les autres pays avec des crit&egrave;res d&rsquo;&eacute;valuations moins sp&eacute;cifiques que pour le score fran&ccedil;ais.<br />
</p>
                </div>
            </div>
        </div> 
    </div>          
</div>
)
}


export default Detailsfr
