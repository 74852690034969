import React from 'react'
import react, { useState, useRef, useEffect} from 'react';
import './App.css';

const QuestionAvecExclusion = ({

    Libellequestion,
    textproposition1,  textproposition2, textproposition3, textproposition4, textproposition5, textproposition6, textproposition7, textproposition8, textproposition9, textproposition10, textproposition11, textproposition12, textproposition13, textproposition14, textproposition15, textproposition16, textproposition17, textproposition18, textproposition19, textproposition20, textproposition21,
    Statut1, Statut2, Statut3, Statut4, Statut5, Statut6, Statut7, Statut8, Statut9, Statut10, Statut11, Statut12, Statut13, Statut14, Statut15, Statut16, Statut17, Statut18, Statut19, Statut20, Statut21,
    
    setParentStatut1, setParentStatut2, setParentStatut3, setParentStatut4, setParentStatut5, setParentStatut6, setParentStatut7, setParentStatut8, setParentStatut9, setParentStatut10, setParentStatut11, setParentStatut12, setParentStatut13, setParentStatut14, setParentStatut15, setParentStatut16, setParentStatut17, setParentStatut18,setParentStatut19, setParentStatut20, setParentStatut21,
    Nbsitemmax, nombredebouton,
    VariableExlu,
    
}) => {



 
    const [Nbsitemselectionne, setNbsitemselectionne] = useState(2)

    const [Variable1, setVariable1] = useState(Statut1)
    const [Variable2, setVariable2] = useState(Statut2)
    const [Variable3, setVariable3] = useState(Statut3)
    const [Variable4, setVariable4] = useState(Statut4)
    const [Variable5, setVariable5] = useState(Statut5)
    const [Variable6, setVariable6] = useState(Statut6)
    const [Variable7, setVariable7] = useState(Statut7)
    const [Variable8, setVariable8] = useState(Statut8)
    const [Variable9, setVariable9] = useState(Statut9)
    const [Variable10, setVariable10] = useState(Statut10)
    const [Variable11, setVariable11] = useState(Statut11)
    const [Variable12, setVariable12] = useState(Statut12)
    const [Variable13, setVariable13] = useState(Statut13)
    const [Variable14, setVariable14] = useState(Statut14)
    const [Variable15, setVariable15] = useState(Statut15)
    const [Variable16, setVariable16] = useState(Statut16)
    const [Variable17, setVariable17] = useState(Statut17)
    const [Variable18, setVariable18] = useState(Statut18)
    const [Variable19, setVariable19] = useState(Statut19)
    const [Variable20, setVariable20] = useState(Statut20)
    const [Variable21, setVariable21] = useState(Statut21)



    useEffect (
        function() {
        setNbsitemselectionne(0)

        if (nombredebouton ===1) {
            setNbsitemselectionne(Variable1)
            if(VariableExlu==1){
                setNbsitemselectionne(1000)
            }
        }

        if (nombredebouton ===2) {
            setNbsitemselectionne(Variable1+Variable2)
            if(VariableExlu==1){
                setNbsitemselectionne(1000)
            }
        }

        if (nombredebouton ===3) {
            setNbsitemselectionne(Variable1+Variable2+Variable3)
            if(VariableExlu==1){
                setNbsitemselectionne(1000)
            }
        }

        if (nombredebouton ===4) {
            setNbsitemselectionne(Variable1+Variable2+Variable3+Variable4)
            if(VariableExlu==1){
                setNbsitemselectionne(1000)
            }
        }

        if (nombredebouton ===5) {
            setNbsitemselectionne(Variable1+Variable2+Variable3+Variable4+Variable5)
            if(VariableExlu==1){
                setNbsitemselectionne(1000)
            }
        }

        if (nombredebouton ===6) {
            setNbsitemselectionne(Variable1+Variable2+Variable3+Variable4+Variable5+Variable6)
            if(VariableExlu==1){
                setNbsitemselectionne(1000)
            }
        }

        if (nombredebouton ===7) {
            setNbsitemselectionne(Variable1+Variable2+Variable3+Variable4+Variable5+Variable6+Variable7)
            if(VariableExlu==1){
                setNbsitemselectionne(1000)
            }
        }

        if (nombredebouton ===8) {
            setNbsitemselectionne(Variable1+Variable2+Variable3+Variable4+Variable5+Variable6+Variable7+Variable8)
            if(VariableExlu==1){
                setNbsitemselectionne(1000)
            }
        }

        if (nombredebouton ===9) {
            setNbsitemselectionne(Variable1+Variable2+Variable3+Variable4+Variable5+Variable6+Variable7+Variable8+Variable9)
            if(VariableExlu==1){
                setNbsitemselectionne(1000)
            }
        }

        if (nombredebouton ===10) {
            setNbsitemselectionne(Variable1+Variable2+Variable3+Variable4+Variable5+Variable6+Variable7+Variable8+Variable9+Variable10)
            if(VariableExlu==1){
                setNbsitemselectionne(1000)
            }
        }

        if (nombredebouton ===11) {
            setNbsitemselectionne(Variable1+Variable2+Variable3+Variable4+Variable5+Variable6+Variable7+Variable8+Variable9+Variable10+Variable11)
            if(VariableExlu==1){
                setNbsitemselectionne(1000)
            }
        }

        if (nombredebouton ===12) {
            setNbsitemselectionne(Variable1+Variable2+Variable3+Variable4+Variable5+Variable6+Variable7+Variable8+Variable9+Variable10+Variable11+Variable12)
            if(VariableExlu==1){
                setNbsitemselectionne(1000)
            }
        }

        if (nombredebouton ===13) {
            setNbsitemselectionne(Variable1+Variable2+Variable3+Variable4+Variable5+Variable6+Variable7+Variable8+Variable9+Variable10+Variable11+Variable12+Variable13)
            if(VariableExlu==1){
                setNbsitemselectionne(1000)
            }
        }

        if (nombredebouton ===14) {
            setNbsitemselectionne(Variable1+Variable2+Variable3+Variable4+Variable5+Variable6+Variable7+Variable8+Variable9+Variable10+Variable11+Variable12+Variable13+Variable14)
            if(VariableExlu==1){
                setNbsitemselectionne(1000)
            }
        }

        if (nombredebouton ===15) {
            setNbsitemselectionne(Variable1+Variable2+Variable3+Variable4+Variable5+Variable6+Variable7+Variable8+Variable9+Variable10+Variable11+Variable12+Variable13+Variable14+Variable15)
            if(VariableExlu==1){
                setNbsitemselectionne(1000)
            }
        }

        if (nombredebouton ===16) {
            setNbsitemselectionne(Variable1+Variable2+Variable3+Variable4+Variable5+Variable6+Variable7+Variable8+Variable9+Variable10+Variable11+Variable12+Variable13+Variable14+Variable15+Variable16)
            if(VariableExlu==1){
                setNbsitemselectionne(1000)
            }
        }

        if (nombredebouton ===17) {
            setNbsitemselectionne(Variable1+Variable2+Variable3+Variable4+Variable5+Variable6+Variable7+Variable8+Variable9+Variable10+Variable11+Variable12+Variable13+Variable14+Variable15+Variable16+Variable17)
            if(VariableExlu==1){
                setNbsitemselectionne(1000)
            }
        }

        if (nombredebouton ===18) {
            setNbsitemselectionne(Variable1+Variable2+Variable3+Variable4+Variable5+Variable6+Variable7+Variable8+Variable9+Variable10+Variable11+Variable12+Variable13+Variable14+Variable15+Variable16+Variable17+Variable18)
            if(VariableExlu==1){
                setNbsitemselectionne(1000)
            }
        }

        if (nombredebouton ===19) {
            setNbsitemselectionne(Variable1+Variable2+Variable3+Variable4+Variable5+Variable6+Variable7+Variable8+Variable9+Variable10+Variable11+Variable12+Variable13+Variable14+Variable15+Variable16+Variable17+Variable18+Variable19)
            if(VariableExlu==1){
                setNbsitemselectionne(1000)
            }
        }

        if (nombredebouton ===20) {
            setNbsitemselectionne(Variable1+Variable2+Variable3+Variable4+Variable5+Variable6+Variable7+Variable8+Variable9+Variable10+Variable11+Variable12+Variable13+Variable14+Variable15+Variable16+Variable17+Variable18+Variable19+Variable20)
            if(VariableExlu==1){
                setNbsitemselectionne(1000)
            }
        }

        if (nombredebouton ===21) {
            setNbsitemselectionne(Variable1+Variable2+Variable3+Variable4+Variable5+Variable6+Variable7+Variable8+Variable9+Variable10+Variable11+Variable12+Variable13+Variable14+Variable15+Variable16+Variable17+Variable18+Variable19+Variable20+Variable21)
            if(VariableExlu==1){
                setNbsitemselectionne(1000)
            }
        }

        

    }
    )

    const onClickb1 =function() {


        if (Variable1 === 0) {
            setParentStatut1(1)
            setVariable1(1)
        }
    
        if (Variable1 === 1) {
            setParentStatut1(0)
            setVariable1(0)
        }
    }

    const onClickb2 =function() {


        if (Variable2 === 0) {
            setParentStatut2(1)
            setVariable2(1)
        }
    
        if (Variable2 === 1) {
            setParentStatut2(0)
            setVariable2(0)
        }
    }

    const onClickb3 =function() {


        if (Variable3 === 0) {
            setParentStatut3(1)
            setVariable3(1)
        }
    
        if (Variable3 === 1) {
            setParentStatut3(0)
            setVariable3(0)
        }
    }

    const onClickb4 =function() {


        if (Variable4 === 0) {
            setParentStatut4(1)
            setVariable4(1)
        }
    
        if (Variable4 === 1) {
            setParentStatut4(0)
            setVariable4(0)
        }
    }

    const onClickb5 =function() {


        if (Variable5 === 0) {
            setParentStatut5(1)
            setVariable5(1)
        }
    
        if (Variable5 === 1) {
            setParentStatut5(0)
            setVariable5(0)
        }
    }

    const onClickb6 =function() {


        if (Variable6 === 0) {
            setParentStatut6(1)
            setVariable6(1)
        }
    
        if (Variable6 === 1) {
            setParentStatut6(0)
            setVariable6(0)
        }
    }

    const onClickb7 =function() {


        if (Variable7 === 0) {
            setParentStatut7(1)
            setVariable7(1)
        }
    
        if (Variable7 === 1) {
            setParentStatut7(0)
            setVariable7(0)
        }
    }

    const onClickb8 =function() {


        if (Variable8 === 0) {
            setParentStatut8(1)
            setVariable8(1)
        }
    
        if (Variable8 === 1) {
            setParentStatut8(0)
            setVariable8(0)
        }
    }

    const onClickb9 =function() {


        if (Variable9 === 0) {
            setParentStatut9(1)
            setVariable9(1)
        }
    
        if (Variable9 === 1) {
            setParentStatut9(0)
            setVariable9(0)
        }
    }

    const onClickb10 =function() {


        if (Variable10 === 0) {
            setParentStatut10(1)
            setVariable10(1)
        }
    
        if (Variable10 === 1) {
            setParentStatut10(0)
            setVariable10(0)
        }
    }

    const onClickb11 =function() {


        if (Variable11 === 0) {
            setParentStatut11(1)
            setVariable11(1)
        }
    
        if (Variable11 === 1) {
            setParentStatut11(0)
            setVariable11(0)
        }
    }

    const onClickb12 =function() {


        if (Variable12 === 0) {
            setParentStatut12(1)
            setVariable12(1)
        }
    
        if (Variable12 === 1) {
            setParentStatut12(0)
            setVariable12(0)
        }
    }

    const onClickb13 =function() {


        if (Variable13 === 0) {
            setParentStatut13(1)
            setVariable13(1)
        }
    
        if (Variable13 === 1) {
            setParentStatut13(0)
            setVariable13(0)
        }
    }

    const onClickb14 =function() {


        if (Variable14 === 0) {
            setParentStatut14(1)
            setVariable14(1)
        }
    
        if (Variable14 === 1) {
            setParentStatut14(0)
            setVariable14(0)
        }
    }

    const onClickb15 =function() {


        if (Variable15 === 0) {
            setParentStatut15(1)
            setVariable15(1)
        }
    
        if (Variable15 === 1) {
            setParentStatut15(0)
            setVariable15(0)
        }
    }

    const onClickb16 =function() {


        if (Variable16 === 0) {
            setParentStatut16(1)
            setVariable16(1)
        }
    
        if (Variable16 === 1) {
            setParentStatut16(0)
            setVariable16(0)
        }
    }

    const onClickb17 =function() {


        if (Variable17 === 0) {
            setParentStatut17(1)
            setVariable17(1)
        }
    
        if (Variable17 === 1) {
            setParentStatut17(0)
            setVariable17(0)
        }
    }

    const onClickb18 =function() {


        if (Variable18 === 0) {
            setParentStatut18(1)
            setVariable18(1)
        }
    
        if (Variable18 === 1) {
            setParentStatut18(0)
            setVariable18(0)
        }
    } 
    
    const onClickb19 =function() {


        if (Variable19 === 0) {
            setParentStatut19(1)
            setVariable19(1)
        }
    
        if (Variable19 === 1) {
            setParentStatut19(0)
            setVariable19(0)
        }
    } 

    const onClickb20 =function() {


        if (Variable20 === 0) {
            setParentStatut20(1)
            setVariable20(1)
        }
    
        if (Variable20 === 1) {
            setParentStatut20(0)
            setVariable20(0)
        }
    } 

    const onClickb21 =function() {


        if (Variable21 === 0) {
            setParentStatut21(1)
            setVariable21(1)
        }
    
        if (Variable21 === 1) {
            setParentStatut21(0)
            setVariable21(0)
        }
    } 

 




        if (nombredebouton===1) {
            return (
                <div>
                <p className="titrebouton">{Libellequestion}</p>

                {Variable1===1 ? <button className="btn btn-success" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne < 1)) ? <button className="btn btn-info" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne >= 1)) ? <button className="btn btn-secondary">{textproposition1} </button> : null}
            
                </div>
            )
        }      
        
        if (nombredebouton===2) {
            return (
                <div>
                <p className="titrebouton">{Libellequestion}</p>

                {Variable1===1 ? <button className="btn btn-success" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne < 1)) ? <button className="btn btn-info" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne >= 1)) ? <button className="btn btn-secondary">{textproposition1} </button> : null}
            
            
                {Variable2===1 ? <button className="btn btn-success" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition2} </button> : null}
                </div>
            )
        }  

        if (nombredebouton===3) {
            return (
                <div>
                <p className="titrebouton">{Libellequestion}</p>

                {Variable1===1 ? <button className="btn btn-success" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne < 1)) ? <button className="btn btn-info" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne >= 1)) ? <button className="btn btn-secondary">{textproposition1} </button> : null}
            
            
                {Variable2===1 ? <button className="btn btn-success" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition2} </button> : null}

                {Variable3===1 ? <button className="btn btn-success" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition3} </button> : null}
                </div>
            )
        }  

        if (nombredebouton===4) {
            return (
                <div>
                <p className="titrebouton">{Libellequestion}</p>

                {Variable1===1 ? <button className="btn btn-success" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne < 1)) ? <button className="btn btn-info" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne >= 1)) ? <button className="btn btn-secondary">{textproposition1} </button> : null}
            
            
                {Variable2===1 ? <button className="btn btn-success" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition2} </button> : null}

                {Variable3===1 ? <button className="btn btn-success" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition3} </button> : null}

                {Variable4===1 ? <button className="btn btn-success" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition4} </button> : null}
                </div>
            )
        }  

        if (nombredebouton===5) {
            return (
                <div>
                <p className="titrebouton">{Libellequestion}</p>

                {Variable1===1 ? <button className="btn btn-success" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne < 1)) ? <button className="btn btn-info" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne >= 1)) ? <button className="btn btn-secondary">{textproposition1} </button> : null}
            
            
                {Variable2===1 ? <button className="btn btn-success" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition2} </button> : null}

                {Variable3===1 ? <button className="btn btn-success" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition3} </button> : null}

                {Variable4===1 ? <button className="btn btn-success" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition4} </button> : null}

                {Variable5===1 ? <button className="btn btn-success" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition5} </button> : null}
                </div>
            )
        }  

        if (nombredebouton===6) {
            return (
                <div>
                <p className="titrebouton">{Libellequestion}</p>

                {Variable1===1 ? <button className="btn btn-success" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne < 1)) ? <button className="btn btn-info" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne >= 1)) ? <button className="btn btn-secondary">{textproposition1} </button> : null}
            
            
                {Variable2===1 ? <button className="btn btn-success" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition2} </button> : null}

                {Variable3===1 ? <button className="btn btn-success" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition3} </button> : null}

                {Variable4===1 ? <button className="btn btn-success" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition4} </button> : null}

                {Variable5===1 ? <button className="btn btn-success" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition5} </button> : null}

                {Variable6===1 ? <button className="btn btn-success" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition6} </button> : null}
                </div>
            )
        }  

        if (nombredebouton===7) {
            return (
                <div>
                <p className="titrebouton">{Libellequestion}</p>

                {Variable1===1 ? <button className="btn btn-success" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne < 1)) ? <button className="btn btn-info" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne >= 1)) ? <button className="btn btn-secondary">{textproposition1} </button> : null}
            
            
                {Variable2===1 ? <button className="btn btn-success" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition2} </button> : null}

                {Variable3===1 ? <button className="btn btn-success" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition3} </button> : null}

                {Variable4===1 ? <button className="btn btn-success" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition4} </button> : null}

                {Variable5===1 ? <button className="btn btn-success" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition5} </button> : null}

                {Variable6===1 ? <button className="btn btn-success" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition6} </button> : null}

                {Variable7===1 ? <button className="btn btn-success" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition7} </button> : null}
                </div>
            )
        }  

        if (nombredebouton===8) {
            return (
                <div>
                <p className="titrebouton">{Libellequestion}</p>

                {Variable1===1 ? <button className="btn btn-success" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne < 1)) ? <button className="btn btn-info" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne >= 1)) ? <button className="btn btn-secondary">{textproposition1} </button> : null}
            
            
                {Variable2===1 ? <button className="btn btn-success" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition2} </button> : null}

                {Variable3===1 ? <button className="btn btn-success" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition3} </button> : null}

                {Variable4===1 ? <button className="btn btn-success" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition4} </button> : null}

                {Variable5===1 ? <button className="btn btn-success" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition5} </button> : null}

                {Variable6===1 ? <button className="btn btn-success" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition6} </button> : null}

                {Variable7===1 ? <button className="btn btn-success" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition7} </button> : null}

                {Variable8===1 ? <button className="btn btn-success" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition8} </button> : null}
                </div>
            )
        }  

        if (nombredebouton===9) {
            return (
                <div>
                <p className="titrebouton">{Libellequestion}</p>

                {Variable1===1 ? <button className="btn btn-success" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne < 1)) ? <button className="btn btn-info" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne >= 1)) ? <button className="btn btn-secondary">{textproposition1} </button> : null}
            
            
                {Variable2===1 ? <button className="btn btn-success" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition2} </button> : null}

                {Variable3===1 ? <button className="btn btn-success" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition3} </button> : null}

                {Variable4===1 ? <button className="btn btn-success" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition4} </button> : null}

                {Variable5===1 ? <button className="btn btn-success" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition5} </button> : null}

                {Variable6===1 ? <button className="btn btn-success" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition6} </button> : null}

                {Variable7===1 ? <button className="btn btn-success" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition7} </button> : null}

                {Variable8===1 ? <button className="btn btn-success" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition8} </button> : null}

                {Variable9===1 ? <button className="btn btn-success" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition9} </button> : null}
                </div>
            )
        }  

        if (nombredebouton===10) {
            return (
                <div>
                <p className="titrebouton">{Libellequestion}</p>

                {Variable1===1 ? <button className="btn btn-success" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne < 1)) ? <button className="btn btn-info" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne >= 1)) ? <button className="btn btn-secondary">{textproposition1} </button> : null}
            
            
                {Variable2===1 ? <button className="btn btn-success" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition2} </button> : null}

                {Variable3===1 ? <button className="btn btn-success" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition3} </button> : null}

                {Variable4===1 ? <button className="btn btn-success" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition4} </button> : null}

                {Variable5===1 ? <button className="btn btn-success" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition5} </button> : null}

                {Variable6===1 ? <button className="btn btn-success" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition6} </button> : null}

                {Variable7===1 ? <button className="btn btn-success" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition7} </button> : null}

                {Variable8===1 ? <button className="btn btn-success" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition8} </button> : null}

                {Variable9===1 ? <button className="btn btn-success" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition9} </button> : null}

                {Variable10===1 ? <button className="btn btn-success" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition10} </button> : null}
                </div>
            )
        }  

        if (nombredebouton===11) {
            return (
                <div>
                <p className="titrebouton">{Libellequestion}</p>

                {Variable1===1 ? <button className="btn btn-success" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne < 1)) ? <button className="btn btn-info" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne >= 1)) ? <button className="btn btn-secondary">{textproposition1} </button> : null}
            
            
                {Variable2===1 ? <button className="btn btn-success" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition2} </button> : null}

                {Variable3===1 ? <button className="btn btn-success" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition3} </button> : null}

                {Variable4===1 ? <button className="btn btn-success" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition4} </button> : null}

                {Variable5===1 ? <button className="btn btn-success" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition5} </button> : null}

                {Variable6===1 ? <button className="btn btn-success" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition6} </button> : null}

                {Variable7===1 ? <button className="btn btn-success" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition7} </button> : null}

                {Variable8===1 ? <button className="btn btn-success" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition8} </button> : null}

                {Variable9===1 ? <button className="btn btn-success" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition9} </button> : null}

                {Variable10===1 ? <button className="btn btn-success" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition10} </button> : null}

                {Variable11===1 ? <button className="btn btn-success" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition11} </button> : null}
                </div>
            )
        }  

        if (nombredebouton===12) {
            return (
                <div>
                <p className="titrebouton">{Libellequestion}</p>

                {Variable1===1 ? <button className="btn btn-success" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne < 1)) ? <button className="btn btn-info" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne >= 1)) ? <button className="btn btn-secondary">{textproposition1} </button> : null}
            
            
                {Variable2===1 ? <button className="btn btn-success" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition2} </button> : null}

                {Variable3===1 ? <button className="btn btn-success" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition3} </button> : null}

                {Variable4===1 ? <button className="btn btn-success" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition4} </button> : null}

                {Variable5===1 ? <button className="btn btn-success" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition5} </button> : null}

                {Variable6===1 ? <button className="btn btn-success" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition6} </button> : null}

                {Variable7===1 ? <button className="btn btn-success" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition7} </button> : null}

                {Variable8===1 ? <button className="btn btn-success" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition8} </button> : null}

                {Variable9===1 ? <button className="btn btn-success" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition9} </button> : null}

                {Variable10===1 ? <button className="btn btn-success" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition10} </button> : null}

                {Variable11===1 ? <button className="btn btn-success" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition11} </button> : null}

                {Variable12===1 ? <button className="btn btn-success" onClick={() => onClickb12()}>{textproposition12} </button> : null}
                {(Variable12===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb12()}>{textproposition11} </button> : null}
                {(Variable12===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition12} </button> : null}
                </div>
            )
        }  

        if (nombredebouton===13) {
            return (
                <div>
                <p className="titrebouton">{Libellequestion}</p>

                {Variable1===1 ? <button className="btn btn-success" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne < 1)) ? <button className="btn btn-info" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne >= 1)) ? <button className="btn btn-secondary">{textproposition1} </button> : null}
            
            
                {Variable2===1 ? <button className="btn btn-success" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition2} </button> : null}

                {Variable3===1 ? <button className="btn btn-success" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition3} </button> : null}

                {Variable4===1 ? <button className="btn btn-success" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition4} </button> : null}

                {Variable5===1 ? <button className="btn btn-success" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition5} </button> : null}

                {Variable6===1 ? <button className="btn btn-success" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition6} </button> : null}

                {Variable7===1 ? <button className="btn btn-success" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition7} </button> : null}

                {Variable8===1 ? <button className="btn btn-success" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition8} </button> : null}

                {Variable9===1 ? <button className="btn btn-success" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition9} </button> : null}

                {Variable10===1 ? <button className="btn btn-success" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition10} </button> : null}

                {Variable11===1 ? <button className="btn btn-success" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition11} </button> : null}

                {Variable12===1 ? <button className="btn btn-success" onClick={() => onClickb12()}>{textproposition12} </button> : null}
                {(Variable12===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb12()}>{textproposition12} </button> : null}
                {(Variable12===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition12} </button> : null}

                {Variable13===1 ? <button className="btn btn-success" onClick={() => onClickb13()}>{textproposition12} </button> : null}
                {(Variable13===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb13()}>{textproposition13} </button> : null}
                {(Variable13===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition13} </button> : null}
                </div>
            )
        }  

        if (nombredebouton===14) {
            return (
                <div>
                <p className="titrebouton">{Libellequestion}</p>

                {Variable1===1 ? <button className="btn btn-success" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne < 1)) ? <button className="btn btn-info" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne >= 1)) ? <button className="btn btn-secondary">{textproposition1} </button> : null}
            
            
                {Variable2===1 ? <button className="btn btn-success" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition2} </button> : null}

                {Variable3===1 ? <button className="btn btn-success" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition3} </button> : null}

                {Variable4===1 ? <button className="btn btn-success" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition4} </button> : null}

                {Variable5===1 ? <button className="btn btn-success" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition5} </button> : null}

                {Variable6===1 ? <button className="btn btn-success" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition6} </button> : null}

                {Variable7===1 ? <button className="btn btn-success" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition7} </button> : null}

                {Variable8===1 ? <button className="btn btn-success" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition8} </button> : null}

                {Variable9===1 ? <button className="btn btn-success" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition9} </button> : null}

                {Variable10===1 ? <button className="btn btn-success" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition10} </button> : null}

                {Variable11===1 ? <button className="btn btn-success" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition11} </button> : null}

                {Variable12===1 ? <button className="btn btn-success" onClick={() => onClickb12()}>{textproposition12} </button> : null}
                {(Variable12===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb12()}>{textproposition12} </button> : null}
                {(Variable12===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition12} </button> : null}

                {Variable13===1 ? <button className="btn btn-success" onClick={() => onClickb13()}>{textproposition13} </button> : null}
                {(Variable13===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb13()}>{textproposition13} </button> : null}
                {(Variable13===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition13} </button> : null}
                
                {Variable14===1 ? <button className="btn btn-success" onClick={() => onClickb14()}>{textproposition14} </button> : null}
                {(Variable14===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb14()}>{textproposition14} </button> : null}
                {(Variable14===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition14} </button> : null}
                </div>
            )
        }  

        if (nombredebouton===15) {
            return (
                <div>
                <p className="titrebouton">{Libellequestion}</p>

                {Variable1===1 ? <button className="btn btn-success" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne < 1)) ? <button className="btn btn-info" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne >= 1)) ? <button className="btn btn-secondary">{textproposition1} </button> : null}
            
            
                {Variable2===1 ? <button className="btn btn-success" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition2} </button> : null}

                {Variable3===1 ? <button className="btn btn-success" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition3} </button> : null}

                {Variable4===1 ? <button className="btn btn-success" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition4} </button> : null}

                {Variable5===1 ? <button className="btn btn-success" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition5} </button> : null}

                {Variable6===1 ? <button className="btn btn-success" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition6} </button> : null}

                {Variable7===1 ? <button className="btn btn-success" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition7} </button> : null}

                {Variable8===1 ? <button className="btn btn-success" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition8} </button> : null}

                {Variable9===1 ? <button className="btn btn-success" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition9} </button> : null}

                {Variable10===1 ? <button className="btn btn-success" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition10} </button> : null}

                {Variable11===1 ? <button className="btn btn-success" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition11} </button> : null}

                {Variable12===1 ? <button className="btn btn-success" onClick={() => onClickb12()}>{textproposition12} </button> : null}
                {(Variable12===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb12()}>{textproposition12} </button> : null}
                {(Variable12===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition12} </button> : null}

                {Variable13===1 ? <button className="btn btn-success" onClick={() => onClickb13()}>{textproposition13} </button> : null}
                {(Variable13===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb13()}>{textproposition13} </button> : null}
                {(Variable13===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition13} </button> : null}
                
                {Variable14===1 ? <button className="btn btn-success" onClick={() => onClickb14()}>{textproposition14} </button> : null}
                {(Variable14===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb14()}>{textproposition14} </button> : null}
                {(Variable14===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition14} </button> : null}

                {Variable15===1 ? <button className="btn btn-success" onClick={() => onClickb15()}>{textproposition15} </button> : null}
                {(Variable15===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb15()}>{textproposition15} </button> : null}
                {(Variable15===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition15} </button> : null}
                </div>
            )
        }  

        if (nombredebouton===16) {
            return (
                <div>
                <p className="titrebouton">{Libellequestion}</p>

                {Variable1===1 ? <button className="btn btn-success" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne < 1)) ? <button className="btn btn-info" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne >= 1)) ? <button className="btn btn-secondary">{textproposition1} </button> : null}
            
            
                {Variable2===1 ? <button className="btn btn-success" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition2} </button> : null}

                {Variable3===1 ? <button className="btn btn-success" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition3} </button> : null}

                {Variable4===1 ? <button className="btn btn-success" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition4} </button> : null}

                {Variable5===1 ? <button className="btn btn-success" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition5} </button> : null}

                {Variable6===1 ? <button className="btn btn-success" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition6} </button> : null}

                {Variable7===1 ? <button className="btn btn-success" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition7} </button> : null}

                {Variable8===1 ? <button className="btn btn-success" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition8} </button> : null}

                {Variable9===1 ? <button className="btn btn-success" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition9} </button> : null}

                {Variable10===1 ? <button className="btn btn-success" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition10} </button> : null}

                {Variable11===1 ? <button className="btn btn-success" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition11} </button> : null}

                {Variable12===1 ? <button className="btn btn-success" onClick={() => onClickb12()}>{textproposition12} </button> : null}
                {(Variable12===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb12()}>{textproposition12} </button> : null}
                {(Variable12===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition12} </button> : null}

                {Variable13===1 ? <button className="btn btn-success" onClick={() => onClickb13()}>{textproposition13} </button> : null}
                {(Variable13===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb13()}>{textproposition13} </button> : null}
                {(Variable13===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition13} </button> : null}
                
                {Variable14===1 ? <button className="btn btn-success" onClick={() => onClickb14()}>{textproposition14} </button> : null}
                {(Variable14===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb14()}>{textproposition14} </button> : null}
                {(Variable14===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition14} </button> : null}

                {Variable15===1 ? <button className="btn btn-success" onClick={() => onClickb15()}>{textproposition15} </button> : null}
                {(Variable15===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb15()}>{textproposition15} </button> : null}
                {(Variable15===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition15} </button> : null}

                {Variable16===1 ? <button className="btn btn-success" onClick={() => onClickb16()}>{textproposition16} </button> : null}
                {(Variable16===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb16()}>{textproposition16} </button> : null}
                {(Variable16===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition16} </button> : null}
                </div>
            )
        }  

        if (nombredebouton===17) {
            return (
                <div>
                <p className="titrebouton">{Libellequestion}</p>

                {Variable1===1 ? <button className="btn btn-success" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne < 1)) ? <button className="btn btn-info" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne >= 1)) ? <button className="btn btn-secondary">{textproposition1} </button> : null}
            
            
                {Variable2===1 ? <button className="btn btn-success" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition2} </button> : null}

                {Variable3===1 ? <button className="btn btn-success" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition3} </button> : null}

                {Variable4===1 ? <button className="btn btn-success" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition4} </button> : null}

                {Variable5===1 ? <button className="btn btn-success" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition5} </button> : null}

                {Variable6===1 ? <button className="btn btn-success" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition6} </button> : null}

                {Variable7===1 ? <button className="btn btn-success" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition7} </button> : null}

                {Variable8===1 ? <button className="btn btn-success" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition8} </button> : null}

                {Variable9===1 ? <button className="btn btn-success" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition9} </button> : null}

                {Variable10===1 ? <button className="btn btn-success" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition10} </button> : null}

                {Variable11===1 ? <button className="btn btn-success" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition11} </button> : null}

                {Variable12===1 ? <button className="btn btn-success" onClick={() => onClickb12()}>{textproposition12} </button> : null}
                {(Variable12===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb12()}>{textproposition12} </button> : null}
                {(Variable12===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition12} </button> : null}

                {Variable13===1 ? <button className="btn btn-success" onClick={() => onClickb13()}>{textproposition13} </button> : null}
                {(Variable13===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb13()}>{textproposition13} </button> : null}
                {(Variable13===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition13} </button> : null}
                
                {Variable14===1 ? <button className="btn btn-success" onClick={() => onClickb14()}>{textproposition14} </button> : null}
                {(Variable14===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb14()}>{textproposition14} </button> : null}
                {(Variable14===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition14} </button> : null}

                {Variable15===1 ? <button className="btn btn-success" onClick={() => onClickb15()}>{textproposition15} </button> : null}
                {(Variable15===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb15()}>{textproposition15} </button> : null}
                {(Variable15===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition15} </button> : null}

                {Variable16===1 ? <button className="btn btn-success" onClick={() => onClickb16()}>{textproposition16} </button> : null}
                {(Variable16===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb16()}>{textproposition16} </button> : null}
                {(Variable16===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition16} </button> : null}

                {Variable17===1 ? <button className="btn btn-success" onClick={() => onClickb17()}>{textproposition17} </button> : null}
                {(Variable17===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb17()}>{textproposition17} </button> : null}
                {(Variable17===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition17} </button> : null}
                </div>
            )
        } 

        if (nombredebouton===18) {
            return (
                <div>
                <p className="titrebouton">{Libellequestion}</p>

                {Variable1===1 ? <button className="btn btn-success" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne < 1)) ? <button className="btn btn-info" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne >= 1)) ? <button className="btn btn-secondary">{textproposition1} </button> : null}
            
            
                {Variable2===1 ? <button className="btn btn-success" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition2} </button> : null}

                {Variable3===1 ? <button className="btn btn-success" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition3} </button> : null}

                {Variable4===1 ? <button className="btn btn-success" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition4} </button> : null}

                {Variable5===1 ? <button className="btn btn-success" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition5} </button> : null}

                {Variable6===1 ? <button className="btn btn-success" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition6} </button> : null}

                {Variable7===1 ? <button className="btn btn-success" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition7} </button> : null}

                {Variable8===1 ? <button className="btn btn-success" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition8} </button> : null}

                {Variable9===1 ? <button className="btn btn-success" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition9} </button> : null}

                {Variable10===1 ? <button className="btn btn-success" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition10} </button> : null}

                {Variable11===1 ? <button className="btn btn-success" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition11} </button> : null}

                {Variable12===1 ? <button className="btn btn-success" onClick={() => onClickb12()}>{textproposition12} </button> : null}
                {(Variable12===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb12()}>{textproposition12} </button> : null}
                {(Variable12===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition12} </button> : null}

                {Variable13===1 ? <button className="btn btn-success" onClick={() => onClickb13()}>{textproposition13} </button> : null}
                {(Variable13===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb13()}>{textproposition13} </button> : null}
                {(Variable13===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition13} </button> : null}
                
                {Variable14===1 ? <button className="btn btn-success" onClick={() => onClickb14()}>{textproposition14} </button> : null}
                {(Variable14===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb14()}>{textproposition14} </button> : null}
                {(Variable14===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition14} </button> : null}

                {Variable15===1 ? <button className="btn btn-success" onClick={() => onClickb15()}>{textproposition15} </button> : null}
                {(Variable15===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb15()}>{textproposition15} </button> : null}
                {(Variable15===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition15} </button> : null}

                {Variable16===1 ? <button className="btn btn-success" onClick={() => onClickb16()}>{textproposition16} </button> : null}
                {(Variable16===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb16()}>{textproposition16} </button> : null}
                {(Variable16===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition16} </button> : null}

                {Variable17===1 ? <button className="btn btn-success" onClick={() => onClickb17()}>{textproposition17} </button> : null}
                {(Variable17===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb17()}>{textproposition17} </button> : null}
                {(Variable17===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition17} </button> : null}

                {Variable18===1 ? <button className="btn btn-success" onClick={() => onClickb18()}>{textproposition18} </button> : null}
                {(Variable18===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb18()}>{textproposition18} </button> : null}
                {(Variable18===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition18} </button> : null}
                </div>
            )
        } 

        if (nombredebouton===19) {
            return (
                <div>
                <p className="titrebouton">{Libellequestion}</p>

                {Variable1===1 ? <button className="btn btn-success" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne < 1)) ? <button className="btn btn-info" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne >= 1)) ? <button className="btn btn-secondary">{textproposition1} </button> : null}
            
            
                {Variable2===1 ? <button className="btn btn-success" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition2} </button> : null}

                {Variable3===1 ? <button className="btn btn-success" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition3} </button> : null}

                {Variable4===1 ? <button className="btn btn-success" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition4} </button> : null}

                {Variable5===1 ? <button className="btn btn-success" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition5} </button> : null}

                {Variable6===1 ? <button className="btn btn-success" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition6} </button> : null}

                {Variable7===1 ? <button className="btn btn-success" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition7} </button> : null}

                {Variable8===1 ? <button className="btn btn-success" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition8} </button> : null}

                {Variable9===1 ? <button className="btn btn-success" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition9} </button> : null}

                {Variable10===1 ? <button className="btn btn-success" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition10} </button> : null}

                {Variable11===1 ? <button className="btn btn-success" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition11} </button> : null}

                {Variable12===1 ? <button className="btn btn-success" onClick={() => onClickb12()}>{textproposition12} </button> : null}
                {(Variable12===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb12()}>{textproposition12} </button> : null}
                {(Variable12===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition12} </button> : null}

                {Variable13===1 ? <button className="btn btn-success" onClick={() => onClickb13()}>{textproposition13} </button> : null}
                {(Variable13===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb13()}>{textproposition13} </button> : null}
                {(Variable13===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition13} </button> : null}
                
                {Variable14===1 ? <button className="btn btn-success" onClick={() => onClickb14()}>{textproposition14} </button> : null}
                {(Variable14===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb14()}>{textproposition14} </button> : null}
                {(Variable14===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition14} </button> : null}

                {Variable15===1 ? <button className="btn btn-success" onClick={() => onClickb15()}>{textproposition15} </button> : null}
                {(Variable15===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb15()}>{textproposition15} </button> : null}
                {(Variable15===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition15} </button> : null}

                {Variable16===1 ? <button className="btn btn-success" onClick={() => onClickb16()}>{textproposition16} </button> : null}
                {(Variable16===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb16()}>{textproposition16} </button> : null}
                {(Variable16===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition16} </button> : null}

                {Variable17===1 ? <button className="btn btn-success" onClick={() => onClickb17()}>{textproposition17} </button> : null}
                {(Variable17===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb17()}>{textproposition17} </button> : null}
                {(Variable17===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition17} </button> : null}

                {Variable18===1 ? <button className="btn btn-success" onClick={() => onClickb18()}>{textproposition18} </button> : null}
                {(Variable18===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb18()}>{textproposition18} </button> : null}
                {(Variable18===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition18} </button> : null}

                {Variable19===1 ? <button className="btn btn-success" onClick={() => onClickb19()}>{textproposition19} </button> : null}
                {(Variable19===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb19()}>{textproposition19} </button> : null}
                {(Variable19===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition19} </button> : null}
                </div>
            )
        } 

        if (nombredebouton===20) {
            return (
                <div>
                <p className="titrebouton">{Libellequestion}</p>

                {Variable1===1 ? <button className="btn btn-success" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne < 1)) ? <button className="btn btn-info" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne >= 1)) ? <button className="btn btn-secondary">{textproposition1} </button> : null}
            
            
                {Variable2===1 ? <button className="btn btn-success" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition2} </button> : null}

                {Variable3===1 ? <button className="btn btn-success" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition3} </button> : null}

                {Variable4===1 ? <button className="btn btn-success" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition4} </button> : null}

                {Variable5===1 ? <button className="btn btn-success" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition5} </button> : null}

                {Variable6===1 ? <button className="btn btn-success" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition6} </button> : null}

                {Variable7===1 ? <button className="btn btn-success" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition7} </button> : null}

                {Variable8===1 ? <button className="btn btn-success" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition8} </button> : null}

                {Variable9===1 ? <button className="btn btn-success" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition9} </button> : null}

                {Variable10===1 ? <button className="btn btn-success" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition10} </button> : null}

                {Variable11===1 ? <button className="btn btn-success" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition11} </button> : null}

                {Variable12===1 ? <button className="btn btn-success" onClick={() => onClickb12()}>{textproposition12} </button> : null}
                {(Variable12===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb12()}>{textproposition12} </button> : null}
                {(Variable12===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition12} </button> : null}

                {Variable13===1 ? <button className="btn btn-success" onClick={() => onClickb13()}>{textproposition13} </button> : null}
                {(Variable13===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb13()}>{textproposition13} </button> : null}
                {(Variable13===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition13} </button> : null}
                
                {Variable14===1 ? <button className="btn btn-success" onClick={() => onClickb14()}>{textproposition14} </button> : null}
                {(Variable14===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb14()}>{textproposition14} </button> : null}
                {(Variable14===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition14} </button> : null}

                {Variable15===1 ? <button className="btn btn-success" onClick={() => onClickb15()}>{textproposition15} </button> : null}
                {(Variable15===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb15()}>{textproposition15} </button> : null}
                {(Variable15===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition15} </button> : null}

                {Variable16===1 ? <button className="btn btn-success" onClick={() => onClickb16()}>{textproposition16} </button> : null}
                {(Variable16===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb16()}>{textproposition16} </button> : null}
                {(Variable16===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition16} </button> : null}

                {Variable17===1 ? <button className="btn btn-success" onClick={() => onClickb17()}>{textproposition17} </button> : null}
                {(Variable17===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb17()}>{textproposition17} </button> : null}
                {(Variable17===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition17} </button> : null}

                {Variable18===1 ? <button className="btn btn-success" onClick={() => onClickb18()}>{textproposition18} </button> : null}
                {(Variable18===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb18()}>{textproposition18} </button> : null}
                {(Variable18===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition18} </button> : null}

                {Variable19===1 ? <button className="btn btn-success" onClick={() => onClickb19()}>{textproposition19} </button> : null}
                {(Variable19===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb19()}>{textproposition19} </button> : null}
                {(Variable19===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition19} </button> : null}

                {Variable20===1 ? <button className="btn btn-success" onClick={() => onClickb20()}>{textproposition20} </button> : null}
                {(Variable20===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb20()}>{textproposition20} </button> : null}
                {(Variable20===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition20} </button> : null}
                </div>
            )
        } 

        if (nombredebouton===21) {
            return (
                <div>
                <p className="titrebouton">{Libellequestion}</p>

                {Variable1===1 ? <button className="btn btn-success" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne < 1)) ? <button className="btn btn-info" onClick={() => onClickb1()}>{textproposition1} </button> : null}
                {(Variable1===0 & (Nbsitemselectionne >= 1)) ? <button className="btn btn-secondary">{textproposition1} </button> : null}
            
            
                {Variable2===1 ? <button className="btn btn-success" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb2()}>{textproposition2} </button> : null}
                {(Variable2===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition2} </button> : null}

                {Variable3===1 ? <button className="btn btn-success" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb3()}>{textproposition3} </button> : null}
                {(Variable3===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition3} </button> : null}

                {Variable4===1 ? <button className="btn btn-success" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb4()}>{textproposition4} </button> : null}
                {(Variable4===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition4} </button> : null}

                {Variable5===1 ? <button className="btn btn-success" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb5()}>{textproposition5} </button> : null}
                {(Variable5===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition5} </button> : null}

                {Variable6===1 ? <button className="btn btn-success" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb6()}>{textproposition6} </button> : null}
                {(Variable6===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition6} </button> : null}

                {Variable7===1 ? <button className="btn btn-success" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb7()}>{textproposition7} </button> : null}
                {(Variable7===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition7} </button> : null}

                {Variable8===1 ? <button className="btn btn-success" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb8()}>{textproposition8} </button> : null}
                {(Variable8===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition8} </button> : null}

                {Variable9===1 ? <button className="btn btn-success" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb9()}>{textproposition9} </button> : null}
                {(Variable9===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition9} </button> : null}

                {Variable10===1 ? <button className="btn btn-success" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb10()}>{textproposition10} </button> : null}
                {(Variable10===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition10} </button> : null}

                {Variable11===1 ? <button className="btn btn-success" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb11()}>{textproposition11} </button> : null}
                {(Variable11===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition11} </button> : null}

                {Variable12===1 ? <button className="btn btn-success" onClick={() => onClickb12()}>{textproposition12} </button> : null}
                {(Variable12===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb12()}>{textproposition12} </button> : null}
                {(Variable12===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition12} </button> : null}

                {Variable13===1 ? <button className="btn btn-success" onClick={() => onClickb13()}>{textproposition13} </button> : null}
                {(Variable13===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb13()}>{textproposition13} </button> : null}
                {(Variable13===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition13} </button> : null}
                
                {Variable14===1 ? <button className="btn btn-success" onClick={() => onClickb14()}>{textproposition14} </button> : null}
                {(Variable14===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb14()}>{textproposition14} </button> : null}
                {(Variable14===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition14} </button> : null}

                {Variable15===1 ? <button className="btn btn-success" onClick={() => onClickb15()}>{textproposition15} </button> : null}
                {(Variable15===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb15()}>{textproposition15} </button> : null}
                {(Variable15===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition15} </button> : null}

                {Variable16===1 ? <button className="btn btn-success" onClick={() => onClickb16()}>{textproposition16} </button> : null}
                {(Variable16===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb16()}>{textproposition16} </button> : null}
                {(Variable16===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition16} </button> : null}

                {Variable17===1 ? <button className="btn btn-success" onClick={() => onClickb17()}>{textproposition17} </button> : null}
                {(Variable17===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb17()}>{textproposition17} </button> : null}
                {(Variable17===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition17} </button> : null}

                {Variable18===1 ? <button className="btn btn-success" onClick={() => onClickb18()}>{textproposition18} </button> : null}
                {(Variable18===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb18()}>{textproposition18} </button> : null}
                {(Variable18===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition18} </button> : null}

                {Variable19===1 ? <button className="btn btn-success" onClick={() => onClickb19()}>{textproposition19} </button> : null}
                {(Variable19===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb19()}>{textproposition19} </button> : null}
                {(Variable19===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition19} </button> : null}

                {Variable20===1 ? <button className="btn btn-success" onClick={() => onClickb20()}>{textproposition20} </button> : null}
                {(Variable20===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb20()}>{textproposition20} </button> : null}
                {(Variable20===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition20} </button> : null}

                {Variable21===1 ? <button className="btn btn-success" onClick={() => onClickb21()}>{textproposition21} </button> : null}
                {(Variable21===0 & (Nbsitemselectionne < Nbsitemmax)) ? <button className="btn btn-info" onClick={() => onClickb21()}>{textproposition21} </button> : null}
                {(Variable21===0 & (Nbsitemselectionne >= Nbsitemmax)) ? <button className="btn btn-secondary">{textproposition21} </button> : null}
                </div>
            )
        } 


        
}

export default QuestionAvecExclusion
